import { Text } from "@clipboard-health/ui-react";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import { Stack } from "@mui/material";
import { useEffect } from "react";

const DEFAULT_TIMEOUT_IN_MILLISECONDS = 3000;

export interface PassToSignatoryPageProps {
  onClose: () => void;
  timeoutInMilliseconds?: number;
}

export function PassPhoneToSignatoryPage(props: PassToSignatoryPageProps): JSX.Element {
  const { timeoutInMilliseconds = DEFAULT_TIMEOUT_IN_MILLISECONDS, onClose } = props;

  useEffect(() => {
    const timeoutId = setTimeout(onClose, timeoutInMilliseconds);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [onClose, timeoutInMilliseconds]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      spacing={2}
      padding={4}
      onClick={onClose}
    >
      <AddToHomeScreenIcon
        sx={{
          fontSize: "3rem",
        }}
      />
      <Text variant="h1">Please pass the device to a facility employee for signature.</Text>
      <Text
        variant="subtitle1"
        sx={{
          color: (theme) => theme.palette.text.disabled,
        }}
      >
        Tap to continue
      </Text>
    </Stack>
  );
}
