import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { LunchBreakInformationIconAndDialog } from "@src/appV2/ShiftBlocks/components/LunchBreakInformationIconAndDialog";
import { type Shift } from "@src/appV2/Shifts/Shift/types";

import { getShiftDuration } from "./ShiftDuration";

interface ShiftDurationWithLunchBreakIconProps {
  shift: Pick<Shift, "start" | "end" | "facility" | "time">;
  timeZone?: string;
}

export function ShiftDurationWithLunchBreakIcon(props: ShiftDurationWithLunchBreakIconProps) {
  const { shift, timeZone } = props;
  const duration = getShiftDuration(shift);

  const shiftStart = formatTime(shift.start, { timeZone });
  const shiftEnd = formatTime(shift.end, { timeZone });

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <Text>
        {shiftStart} - {shiftEnd}
      </Text>
      <Text>
        <Box
          component="span"
          sx={{
            opacity: 0.6,
          }}
        >
          {duration}
        </Box>{" "}
        {shift.time && (
          <LunchBreakInformationIconAndDialog
            shiftDetails={{
              facilityName: shift.facility.name,
              shiftTime: shift.time,
            }}
          />
        )}
      </Text>
    </Stack>
  );
}
