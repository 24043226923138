import { InternalLink, Text } from "@clipboard-health/ui-react";
import ReportIcon from "@mui/icons-material/Report";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import pluralize from "pluralize";

export interface UnverifiedShiftsCardProps {
  agentId?: string;
  unverifiedShiftsCount: number;
  missingTimesheetsCount: number;
  extraWorkedTimeRequestsCount: number;
}

export function UnverifiedShiftsCard(props: UnverifiedShiftsCardProps) {
  const { unverifiedShiftsCount, missingTimesheetsCount, extraWorkedTimeRequestsCount, agentId } =
    props;

  return (
    <Card variant="outlined">
      <CardActionArea
        component={InternalLink}
        to={DeprecatedGlobalAppV1Paths.UNVERIFIED_SHIFTS}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.UNVERIFIED_SHIFTS_CARD_CLICKED, {
            agentId,
          });
        }}
      >
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={2}>
            {missingTimesheetsCount > 0 ? <ReportIcon color="error" /> : <ScheduleIcon />}
            <Stack>
              {extraWorkedTimeRequestsCount > 0 ? (
                <Text variant={missingTimesheetsCount > 0 ? "body2" : "body1"}>
                  {extraWorkedTimeRequestsCount} break{" "}
                  {pluralize("payment", extraWorkedTimeRequestsCount)} pending approval
                </Text>
              ) : null}
              {unverifiedShiftsCount > 0 ? (
                <Text variant="body2">
                  {unverifiedShiftsCount} {pluralize("shift", unverifiedShiftsCount)} waiting for
                  verification
                </Text>
              ) : null}
              {missingTimesheetsCount > 0 ? (
                <Text>
                  {missingTimesheetsCount} Missing {pluralize("Timesheet", missingTimesheetsCount)}
                </Text>
              ) : null}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
