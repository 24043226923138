import { isDefined } from "@clipboard-health/util-ts";

export function formatRate(amount?: number): string {
  if (!isDefined(amount)) {
    return "-";
  }

  const rateFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return rateFormatter.format(amount);
}
