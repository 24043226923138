import { Text } from "@clipboard-health/ui-react";
import BoltIcon from "@mui/icons-material/Bolt";
import { Stack } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { type Shift, UrgencyType } from "@src/appV2/Shifts/Shift/types";

import { formatRate } from "../../utils";

function getNonInstantPayAmount(
  shift: Shift,
  options: { isOriginalAmountFieldEnabled: boolean }
): number {
  if (options.isOriginalAmountFieldEnabled) {
    return shift.originalAmount!;
  }

  return shift.finalPay! * shift.time!;
}

interface ShiftPaymentProps {
  shift: Shift;
}

export function ShiftPayment(props: ShiftPaymentProps) {
  const { shift } = props;

  const isUrgentShiftsHourlyPayRateEnabled = useCbhFlag(
    CbhFeatureFlag.URGENT_SHIFTS_HOURLY_PAY_RATE,
    { defaultValue: true }
  );

  const isOriginalAmountFieldEnabled = useCbhFlag(CbhFeatureFlag.USE_ORIGINALAMOUNT_FIELD_NON_IP, {
    defaultValue: false,
  });

  const isUrgentShift = shift.urgentlyBooked && shift.urgency === UrgencyType.NCNS;
  const isHourlyPayRate = !isUrgentShift || isUrgentShiftsHourlyPayRateEnabled;

  const calculateFormattedRate = () => {
    if (isHourlyPayRate) {
      return `${formatRate(shift.finalPay)} / hr`;
    }

    if (shift.isInstantPay) {
      const instantPayAmount = shift.instantPayDetails?.totalAmount;
      return `${formatRate(instantPayAmount)} total earnings`;
    }

    const nonInstantPayAmount = getNonInstantPayAmount(shift, { isOriginalAmountFieldEnabled });
    return `${formatRate(nonInstantPayAmount)} total earnings`;
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      <Text variant="body2" sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }}>
        {calculateFormattedRate()}
      </Text>
      {shift.isInstantPay ? (
        <Stack direction="row" spacing={0.25}>
          <BoltIcon sx={{ color: (theme) => theme.palette.success.main }} />
          <Text
            variant="body2"
            sx={{ color: (theme) => theme.palette.success.main, fontWeight: 600 }}
            align="center"
          >
            {shift.instantPayDetails?.is100InstantPayEnabled ? null : (
              <>{formatRate(shift.instantPayDetails?.totalAmount)} </>
            )}
            InstantPay
          </Text>
        </Stack>
      ) : null}
    </Stack>
  );
}
