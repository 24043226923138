import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { addDays, endOfDay, isAfter, isBefore, parseISO } from "date-fns";

function getShiftSchedule(shift: Shift): string | undefined {
  const now = new Date();
  const tomorrow = addDays(now, 1);

  const shiftStart = parseISO(shift.start);
  const shiftEnd = parseISO(shift.end);

  if (isAfter(now, shiftStart) && isBefore(now, shiftEnd)) {
    return "Shift In Progress";
  }

  if (isBefore(now, shiftStart) && isBefore(shiftStart, endOfDay(now))) {
    return "Today";
  }

  if (isBefore(endOfDay(now), shiftStart) && isBefore(shiftStart, endOfDay(tomorrow))) {
    return "Tomorrow";
  }

  return undefined;
}

interface ShiftScheduleProps {
  shift: Shift;
}

export function ShiftSchedule(props: ShiftScheduleProps) {
  const { shift } = props;

  const schedule = getShiftSchedule(shift);
  const shiftStartDate = formatShortDateWithWeekday(shift.start, { timeZone: shift.facility.tmz });

  if (!isDefined(schedule)) {
    return <Text variant="h3">{shiftStartDate}</Text>;
  }

  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <Text variant="h3" color="#1c69d1">
        {schedule}
      </Text>
      {isBefore(new Date(), parseISO(shift.start)) ? (
        <Text variant="h3">{shiftStartDate}</Text>
      ) : null}
    </Stack>
  );
}
