import { InternalLink, Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useGetAttendanceScoreHistory } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreHistory";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";

import { formatRate } from "../utils";

interface Props {
  modalState: UseModalState;
  shiftEarnings: number;
  shiftId: string;
  showWorkplaceRatingDialog: () => void;
  agentId: string;
}

const WORK_SHIFT_ACTIONS = new Set(["WORK_SHIFT", "CLOCKIN_TIMELINESS_BONUS", "START_SHIFT_LATE"]);

export function WorkedShiftFeedbackModal(props: Props) {
  const { modalState, shiftEarnings, shiftId, showWorkplaceRatingDialog, agentId } = props;
  const worker = useDefinedWorker();

  const { data: attendanceScoreLogs, isSuccess: scoreLogsIsSuccess } = useGetAttendanceScoreHistory(
    {
      userId: agentId,
      filter: { shiftIds: [shiftId] },
    }
  );

  const workShiftScoreLogs =
    attendanceScoreLogs?.scoreLogs
      .filter((scoreLog) => WORK_SHIFT_ACTIONS.has(scoreLog.action))
      .sort((a, b) => parseISO(a.createdAt).getTime() - parseISO(b.createdAt).getTime()) ?? [];

  const scoreLogMap = new Map(workShiftScoreLogs.map((log) => [log.action, log.points]));
  const firstScoreLog = workShiftScoreLogs[0];

  const attendanceScoreBeforeWorkShift = isDefined(firstScoreLog)
    ? firstScoreLog.score - firstScoreLog.points
    : undefined;

  const renderScoreChange = (action: string, label: string) => {
    const actionPoint = scoreLogMap.get(action);
    if (!isDefined(actionPoint)) {
      return null;
    }

    let color;
    if (actionPoint > 0) {
      color = action === "START_SHIFT_LATE" ? "red" : "green";
    }

    const sign = action === "START_SHIFT_LATE" ? "" : "+";
    const pointsText = actionPoint === 0 ? "-" : `${sign}${actionPoint}`;

    return (
      <Box display="flex" justifyContent="space-between">
        <Text variant="body2">{label}</Text>
        <Text variant="body2" color={color}>
          {pointsText}
        </Text>
      </Box>
    );
  };

  const workShiftPoints = scoreLogMap.get("WORK_SHIFT") ?? 0;

  return (
    <Dialog
      open={modalState.modalIsOpen}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
      onClose={modalState.closeModal}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <CheckCircleIcon style={{ color: "green", fontSize: "3rem" }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack textAlign="center" spacing={1.5}>
          <Text variant="subtitle1">Nice work! You’ve earned</Text>
          <Text bold variant="h3">
            {formatRate(shiftEarnings)}
          </Text>
          <Text variant="body2">You can view your instant pay status in your payroll page</Text>
          {scoreLogsIsSuccess && isDefined(attendanceScoreBeforeWorkShift) && (
            <Stack textAlign="left" spacing={0.5}>
              <Text bold variant="subtitle1">
                Attendance Score:
              </Text>
              <Box display="flex" justifyContent="space-between">
                <Text variant="body2">Before</Text>
                <Text variant="body2">{attendanceScoreBeforeWorkShift}</Text>
              </Box>
              {worker.stage === AgentStage.RESTRICTED && workShiftPoints === 0 ? (
                <Box display="flex" justifyContent="space-between">
                  <Text variant="body2">Account restricted</Text>
                  <Text variant="body2">-</Text>
                </Box>
              ) : (
                renderScoreChange("WORK_SHIFT", "Work a shift")
              )}
              {renderScoreChange("CLOCKIN_TIMELINESS_BONUS", "Clocking in on time")}
              {renderScoreChange("START_SHIFT_LATE", "Clocking in late")}
              <Divider />
              <Box display="flex" justifyContent="space-between">
                <Text variant="body2">After</Text>
                <Text variant="body2">{workShiftScoreLogs.at(-1)?.score}</Text>
              </Box>
              <InternalLink
                to={DeprecatedGlobalAppV1Paths.RATINGS_ATTENDANCE_SCORE}
                textAlign="right"
                paddingTop={1}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.ATTENDANCE_SCORE_PAGE_OPENED_FROM_FEEDBACK_MODAL, {
                    agentId,
                    shiftId,
                  });
                }}
              >
                See Attendance Score details
              </InternalLink>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            modalState.closeModal();
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_OPENED_FROM_FEEDBACK_MODAL, {
              agentId,
              shiftId,
            });
            showWorkplaceRatingDialog();
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
